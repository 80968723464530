<template>
  <v-form ref="form" v-model="valid">
    <v-row class="ma-4 form__border">
      <v-col cols="12">
      </v-col>
      <v-col cols="6">
        <v-text-field
            clearable
            :placeholder="$t('common.titles.name')"
            outlined
            dense
            color="primary"
            :rules="required"
            v-model="wallet.name"
            :hide-details="valid"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            clearable
            :placeholder="$t('inventory.fields.wallet')"
            outlined
            dense
            color="primary"
            :rules="validateAddress"
            v-model="wallet.address"
            :hide-details="valid"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
            clearable
            :placeholder="$t('company.label')"
            outlined
            dense
            color="primary"
            :items="companies"
            :rules="required"
            item-text="name"
            item-value="id"
            item-color="primary"
            v-model="wallet.companyId"
            :hide-details="valid"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6" class="d-flex justify-end align-content-center align-center">
        <v-btn elevation="0"
               :class="valid ? '' : 'mb-6'"
               @click="addNewWallet()"
               :disabled="!valid || loading"
               color="primary"
               class="t-bw-primary--text"
               :loading="loading"
        >
          {{ $t('common.buttons.save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "WalletFormComponent",

  data: () => ({
    valid: false,
    loading: false,
    wallet: {
      address: null,
      name: null,
      companyId: null
    }
  }),

  watch: {
    valid() {
      this.$emit('isValid', this.valid)
    }
  },

  mounted() {
    this.$root.$on('onCLoseForm', () => {
      this.close()
    })
  },

  computed: {
    ...mapGetters({
      companies: 'company/allCompanies',
      required: 'general/requireRules',
      validateAddress: 'general/ethereumAddress'
    }),
    wallets: {
      get() {
        return this.$store.getters['web3/GET_WALLETS']
      },
      set(val) {
        this.$store.commit('web3/WALLETS_DATA', val)
      }
    },
  },

  methods: {
    ...mapActions({
      createWallet: 'web3/CREATE_WALLET',
    }),
    async addNewWallet() {
      this.loading = true

      await this.createWallet(this.wallet).finally(() => {
        this.close()
      })
    },
    close() {
      this.loading = false;
      this.wallet = {
        address: null,
        name: null,
        companyId: null
      }

      this.$refs.form.resetValidation()
    }
  },

  destroyed() {
    this.$root.$off('onCLoseForm')
  }
}
</script>

<style scoped>
.form__border {
  border: 1px dashed #80808030;
  border-radius: 0.3em;
}
</style>