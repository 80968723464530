<template>
  <v-dialog
      v-model="dialog"
      max-width="650px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          color="neuter"
          class="mr-1"
          v-bind="attrs"
          v-on="on"
          @click="initClone"
      >
        mdi-square-edit-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="secondary t-bw-secondary--text">
        <span class="text-h5">Editar cartera</span>
      </v-card-title>

      <v-card-text class="pa-6">
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="Nombre"
                    outlined
                    dense
                    color="primary"
                    :rules="required"
                    v-model="clone.name"
                    :hide-details="valid"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="Cartera"
                    outlined
                    dense
                    color="primary"
                    :rules="validateAddress"
                    v-model="clone.address"
                    :hide-details="valid"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                    clearable
                    label="Company"
                    outlined
                    dense
                    color="primary"
                    :items="companies"
                    :rules="required"
                    item-value="id"
                    item-text="name"
                    item-color="primary"
                    v-model="clone.companyId"
                    :hide-details="valid"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn
            color="neuter"
            small
            text
            @click="close"
            :disabled="loading"
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
            color="primary"
            small
            elevation="0"
            class="t-bw-primary--text"
            @click="update"
            :disabled="!valid || loading"
            :loading="loading"
        >
          {{ $t('common.buttons.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WalletEditDialog",

  props: {
    wallet: {
      type: Object,
      required: true
    }
  },

  data:() => ({
    dialog: false,
    valid: false,
    loading: false,
    clone: {
      address: null,
      name: null,
      companyId: null
    }
  }),

  computed: {
    ...mapGetters({
      companies: 'company/allCompanies',
      required: 'general/requireRules',
      validateAddress: 'general/ethereumAddress'
    })
  },

  methods: {
    ...mapActions({
      updateWallet: 'web3/UPDATE_WALLET'
    }),
    initClone() {
      this.clone = {
        name: this.wallet.name,
        companyId: this.wallet.company.id,
        address: this.wallet.address,
        id: this.wallet.id
      }
    },
    async update() {
      this.loading = true
      await this.updateWallet(this.clone).finally(() => {
        this.close()
      });
    },
    close() {
      this.$emit('onWalletChange', this.wallet)

      this.clone = {
        address: null,
        name: null,
        companyId: null
      }
      this.dialog = false
      this.loading = false
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style scoped>
.v-icon {
  font-size: 16px !important;
}
</style>